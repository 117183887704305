<template>
  <UserDetailItemEditCard confirmation-message="Naam werd succesvol gewijzigd"
                      title="Bewerk naam" @save="updateCurrentUserData"
                      :detailViewRouteLocation="{name: routeNames.USER_DETAIL, params: { userId: userId}}"
                      update-store-action="usersModule/updateUserName" :update-store-action-payload="{userName: this.userName, userId: this.userId}" 
                      :userId="userId" :promises.sync="promises">
    <template v-slot:form-fields>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field required persistent-placeholder label="Voornaam" type="text" class="min-w-100"
                        v-model="userName.firstName" :rules="firstNameRules"/>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field required persistent-placeholder label="Achternaam" type="text" class="min-w-100"
                        v-model="userName.lastName" :rules="lastNameRules"/>
        </v-col>
      </v-row>
    </template>
  </UserDetailItemEditCard>
</template>

<script>

import routeNames from "@/router/RouteNames";
import UserDetailItemEditCard from "@/views/user/forms/UserDetailItemEditCard.vue"

export default {
  name: "UserDetailHeaderEditName.vue",
  components: {
    UserDetailItemEditCard
  },
  props: {
    userId: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      routeNames: routeNames,
      firstNameRules: [
        v => !!v || 'Voornaam moet ingevuld worden',
      ],
      lastNameRules: [
        v => !!v || 'Achternaam moet ingevuld worden',
      ],
      displaySuccessBanner: false,
      updateSuccessMsg: "Naam is succesvol aangepast.",
      userName: {
        firstName: "",
        lastName: ""
      },
      promises: [
        this.$store.dispatch('usersModule/fetchUserName', this.userId)
          .then((userName) => this.userName = userName)
      ]
    }
  },
  methods: {
    updateCurrentUserData() {
      // username of user has updated
      // ensure the username will be displayed correctly at other locations (headerbar)
      
      this.$store.commit("authModule/setUserName", {userName: this.userName, userId: this.userId })
    },
    saveUserNameUpdate() {
      this.$store.dispatch('usersModule/updateUserName', {userId: this.userId, userName: this.userName})
          .then(() => {
            this.displaySuccessBanner = true
            this.$router.push({name: routeNames.USER_DETAIL, params: {userId: this.userId}})
          })
    },
  },
}
</script>
